


























































































import {
    apiPrintLists,
    apiDelPrint,
    apiTestPrint,
    apiAutoPrint
} from '@/api/application/print'
import { Component, Prop, Vue } from 'vue-property-decorator'
import LsPagination from '@/components/ls-pagination.vue'
import { RequestPaging } from '@/utils/util'
import { PageMode } from '@/utils/type'
import LsDialog from '@/components/ls-dialog.vue'
@Component({
    components: {
        LsDialog,
        LsPagination
    }
})
export default class Task extends Vue {
    // 分页
    pager: RequestPaging = new RequestPaging()

    // 获取列表
    getList() {
        this.pager
            .request({
                callback: apiPrintLists,
                params: {}
            })
            .catch(() => {
                this.$message.error('数据请求失败，刷新重载!')
            })
    }

    // 是否自动打印
    async handleStatusPrintSet(row: any) {
        await apiAutoPrint({ id: row.id })
        this.getList()
    }

    // 测试打印
    async onTestPrintFunc(id: number) {
        await apiTestPrint({ id })
    }

    // 删除这个打印机
    onDel(id: any) {
        apiDelPrint({ id: id })
            .then(() => {
                // 删除成功就请求新列表
                this.getList()
                this.$message.success('删除成功!')
            })
            .catch(() => {
                this.$message.error('删除失败!')
            })
    }

    // 新增
    goTaskAdd() {
        this.$router.push({
            path: '/print/edit_print',
            query: {
                mode: PageMode.ADD
            }
        })
    }

    // 编辑
    goTaskEdit(id: any) {
        this.$router.push({
            path: '/print/edit_print',
            query: {
                id: id,
                mode: PageMode.EDIT
            }
        })
    }

    created() {
        this.getList()
    }
}
